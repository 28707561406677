<template>
  <div class="m-feedback-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('feedback.feedbackDeleteError') }}
    </b-notification>
    <b-table :data="feedback" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'feedback-edit', params: { id: props.row.id } }">{{ props.row.id }}</router-link>
      </b-table-column>

      <b-table-column field="fromUser" :label="$t('feedback.from')" sortable searchable v-slot="props">
        {{ props.row.fromUser }}
      </b-table-column>

      <b-table-column field="toUser" :label="$t('feedback.to')" sortable searchable v-slot="props">
        {{ props.row.toUser }}
      </b-table-column>

      <b-table-column field="type" :label="$t('feedback.type')" sortable searchable v-slot="props">
        {{ props.row.type }}
      </b-table-column>

      <b-table-column field="value" :label="$t('feedback.value')" v-slot="props">
        {{ props.row.value }}
      </b-table-column>

      <b-table-column field="created" :label="$t('generic.createdDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.created }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row.id, props.row.type)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'FeedbackList',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    feedback() {
      let data = [];
      if (this.$store.state.feedback.all) {
        this.$store.state.feedback.all.forEach(item => {
          data.push({
            id: item._id,
            fromUser: item.fromUser ? item.fromUser.nameLast + ' ' + item.fromUser.nameFirst : '',
            toUser: item.toUser ? item.toUser.nameLast + ' ' + item.toUser.nameFirst : '',
            type: this.$t('feedback.types[' + (item.type - 1) + '].title'),
            value: this.$t('feedback.thanks[' + (item.value - 1) + '].title'),
            created: new Date(item.createdAt).toLocaleDateString()
          });
        });
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.reloadFeedback();
  },
  methods: {
    reloadFeedback() {
      this.loading = true;
      this.$store.dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_LIST).then(() => {
        this.loading = false;
      });
    },
    confirmDelete(feedbackId) {
      this.$buefy.dialog.confirm({
        message: this.$t('feedback.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.FEEDBACK + actions.DELETE_FEEDBACK, feedbackId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
